@mixin media-laptop {
    @media screen and (max-width: 1500px) {
        @content;
    }
}

@mixin media-small-desktop {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

@mixin media-tablet {
    @media screen and (max-width: 1150px) {
        @content;
    }
}

@mixin media-mobile {
    @media screen and (max-width: 850px) {
        @content;
    }
}

$health-colour: #28336E;
$education-colour: #E0603A;
$councils-colour: #9A8373;
$energy-colour: #67B85D;
$water-colour: #5F6AB9;
$sports-colour: #EEA793;
$infrastructure-colour: #45413E;

$blue: rgba(54, 62, 124, 1);
$blue-pastel: rgba(95, 106, 185, 1);
$green: rgba(154, 205, 146, 1);
$dark-green: rgba(103, 184, 92, 1);
$brown: rgba(154, 131, 115, 1);
$dark-grey: rgba(69, 65, 62, 1);
$orange: rgba(227, 98, 58, 1);
$brown-pastel: rgba(199, 182, 174, 1);
$orange-pastel: rgba(241, 166, 145, 1);
$grey-medium: rgba(121, 113, 111, 1);
$grey-medium-light: rgba(183, 180, 189, 1);
$grey-light: rgba(236, 236, 238, 1);
$blue-light-background: rgba(246, 248, 252, 1);
$blue-darker: rgba(30, 38, 83, 1);
$mustard: rgba(254, 153, 0, 1);

// from dam safety
$color-blue: #0096cd;
$color-red: #e81d2a;
$color-yellow: #f1ac00;
$color-green: #2b9a44;
$color-black: #000000;
$color-dark-grey: #4c4c4c;
$color-light-grey: #f7f7f7;
$color-shadow-grey: #ededed;
$color-white: #ffffff;
$purple: #28336e;
$mid-grey: #87878a;

$small-desktop: 1200px;
$mobile: 992px;
$small-mobile: 600px;
//--

h6 {
    text-transform: uppercase;
    font-size: 12px;
    color: $grey-medium;
    margin: 0;
    font-weight: normal;
}
