@import '../../variables.scss';

.searchLabel {
	color: $purple;
	font-size: 24px;
	line-height: 31px;
	font-weight: normal;
  display: inline;
  .searchLabelText {
    display: inline-block;
    margin: 0 0 24px 0;
  }
}

.geoSearchInputWrapper {
  position: relative;
  margin-bottom: 20px;
  svg {
    height: 20px !important;
  }
}

.geoSearchInputImageWrapper {
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 55px;
  z-index: 10000;
  background: $purple;
  height: 45px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
}
.geoSearchInputImage {
  width: 14px;
  height: 14px;
}
.option {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.optionCouncil {
  color: $purple;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: right;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: $mobile) {
    justify-content: flex-start;
  }
}

.presets {
  color: #28336e;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  .preset {
    text-decoration: underline;
    cursor: pointer;
  }
}