@import "../../variables.scss";

.aboutWrapper {
	// position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
	background-color: white;
	border-radius: 10px 10px 0 0;
	z-index: 1000;
	text-align: center;
	height: auto;
	border: 1px solid #e3e4e6;
	border-radius: 8px 8px 0 0;
	margin: 50px 0;
	@media (max-width: $mobile) {
		height: auto;
		padding: 26px 0;
	}

	h2 {
		line-height: normal;
		color: $purple;
		@media (max-width: $mobile) {
			text-align: left;
			margin: 0 17px 20px !important;
		}
	}
}

.aboutHeading {
	font-size: 40px !important;
	margin: 30px 16px 30px 0 !important;
	font-weight: 600;
	@media (max-width: 950px) {
	  font-size: 36px !important;
	}
	@media (max-width: 900px) {
	  font-size: 30px !important;
	}
	@media (max-width: 850px) {
	  font-size: 27px !important;
	}
}

.row {
	display: flex;
	justify-content: center;
	@media (max-width: $mobile) {
	  flex-direction: column;
	  justify-content: flex-start;
	}
}

.statItem {
	display: flex;
	margin: 0 30px 30px 0;
	width: 150px;
	// gap: 20px;
	@media (max-width: $mobile) {
		margin: 0 17px 20px;
		width: 80%;
	}
	&.wide {
		width: 690px;
		@media (max-width: $mobile) {
			width: 80%;
		}
	}
	.content {
		text-align: left;
		display: flex;
		flex-direction: column;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 1em;
		color: $color-dark-grey;
		margin-left: 10px;
		gap: 5px;
		@media (max-width: $mobile) {
			gap: 0;
		}
	}

	.value {
		color: $purple;
		font-size: 20px;
		line-height: 24px;
		// max-height: 24px;
		overflow: hidden;
		@media (max-width: $mobile) {
			margin-bottom: 5px;
		}
		&.small {
			font-size:12px;
			line-height: 115%;
			font-weight: 400;
		}
		&[data-data-available="false"] {
			color: $grey-medium;
			font-weight: 400px;
			// font-size: 14px;
			font-size:11px;
			line-height: 110%;
		}
	}
}

.legend {
	margin: 5px 10px 10px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'Montserrat', sans-serif;
	@media (max-width: $mobile) {
		margin-left: 17px;
		margin-bottom: 0px;
		text-align: left;
	}
	a {
		color: $color-dark-grey;
		margin-left: 3px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.externalLink {
	text-decoration: underline;
  }