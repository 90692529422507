@import "../../variables.scss";

.regionsWrapper {
	width: 100%;
	background-color: white;
	border-radius: 10px 10px 0 0;
	z-index: 1000;
	text-align: center;
	height: auto;
	border: 1px solid #e3e4e6;
	border-radius: 8px 8px 0 0;
	margin: 50px 0;
	padding: 0 0 30px 0;
	@media (max-width: $mobile) {
		height: auto;
		padding: 26px 0;
	}
	h2 {
		line-height: normal;
		text-align: center;
		color: $purple;
		font-size: 40px !important;
		margin: 30px 16px 30px 0 !important;
		font-weight: 600;
		@media (max-width: 950px) {
			font-size: 36px !important;
		}
		@media (max-width: 900px) {
			font-size: 30px !important;
		}
		@media (max-width: 850px) {
			font-size: 27px !important;
		}
		@media (max-width: $mobile) {
			text-align: left;
			margin: 0 17px !important;
		}
	}
	.regions {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0;
		gap: 30px;
		@media (max-width: $mobile) {
			gap: 0;
		}
	}
}

.regions {
	.region {
		width: 230px;
		text-align: left;
		@media (max-width: $mobile) {
			text-align: left;
			margin: 0 17px 30px !important;
			&:last-child {
				margin: 0 17px !important;
			}
		}
	}
	.label {
		border: none;
		display: block;
		cursor: pointer;
		background: none;
		font-size: 15px;
		color: #707073;
		margin-left:20px;
		width: 214px;
		text-align: left;
		@media (max-width: $mobile) {
			margin: 10px 20px 30px;
		}
	}
	.region {
		span{
			color: $purple;
			font-size: 24px;
			font-weight: 600;
			line-height: 31px;
		}
		p {
			font-family: Montserrat;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5em;
			letter-spacing: 0em;
			text-align: left;
			margin: 10px 0 0;
		}
	}
}