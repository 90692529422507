@import "../../variables.scss";

.navigationWrapper {
	margin-bottom: 50px;
	@media (max-width: $mobile) {
		display: none;
	}
	.navigation {
		display: flex;
		justify-content: space-between;
		position: relative;
		padding-inline-start: 0;
	}
}

.section {
	min-width: 135px;
	flex-basis: 218px;
	background-color: $color-light-grey;
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	margin-right: 16px;
	border: 1px solid #e3e4e6;

	&:last-child {
		margin-right: 0;
	}

	a {
		width: 100%;
	}
}

.heading {
	font-size: 20px;
	line-height: 20px;
	max-width: 100%;
	padding-bottom: 12px;
	margin-bottom: .5rem;
	display: inline-block;
	@media (max-width: 1200px) {
		font-size: 16px;
		line-height: 20px;
	}
}

.button {
	border: none;
	width: 100%;
	height: 41px;
	cursor: pointer;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 0;
	&[data-topic="health"] {
		background-color: #28336e;
	}
	&[data-topic="education"] {
		background-color: #e0603a;
	}
	&[data-topic="councils"] {
		background-color: #9a8373;
	}
	&[data-topic="energy"] {
		background-color: #67b85d;
	}
	&[data-topic="water"] {
		background-color: #5f6ab9;
	}
	&[data-topic="sports"] {
		background-color: #eea793;
	}
	&[data-topic="infrastructure"] {
		background-color: #45413e;
	}
}
.chevron {
	margin-bottom: 5px;
}
