@import "../../variables.scss";

.sectionWrapper {
	margin: 50px 0;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	padding: 28px;
	padding-bottom: 40px;
	border: 1px solid #e3e4e6;

	@media (max-width: $mobile) {
		margin-top: 0px;
		padding: 26px 17px;
	}
}

.topic {
	.headerRow {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;
		margin-bottom: 20px;
		transition: opacity 0.5s, transform 0.5s;

		> div {
			margin-right: 20px;
		}

		&.first {
			margin-bottom: 20px;
		}

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		a {
			font-size: 14px;
			line-height: 22px;
			font-weight: 600;
			color: rgba(95, 106, 185, 1);
		}
	}

	.factoids {
		display: flex;
		justify-content: flex-start;
		position: relative;
		flex-wrap: wrap;
		gap: 20px;
		@media (max-width: $mobile) {
			gap: 0;
			margin-bottom: 20px;

		}
	}
}

.headerRow {
	h3 {
		display: inline-block;
		color: #333333;
		font-size: 30px;
		line-height: 38px;
		position: relative;
		margin-bottom: 10px;

		.line {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 50%;
			height: 3px;
			margin: 0;
			border: none;
			&[data-topic="health"] {
				background-color: $health-colour;
			}
			&[data-topic="education"] {
				background-color: $education-colour;
			}
			&[data-topic="councils"] {
				background-color: $councils-colour;
			}
			&[data-topic="energy"] {
				background-color: $energy-colour;
			}
			&[data-topic="water"] {
				background-color: $water-colour;
			}
			&[data-topic="sports"] {
				background-color: $sports-colour;
			}
			&[data-topic="infrastructure"] {
				background-color: $infrastructure-colour;
			}
		}
		@media (max-width: $mobile) {
			font-size: 27px;
		}
	}

	.comparisonWrapper {
		// display: inline-block;
		// width: 50%;
		display: flex;
		width: 50%;
		margin-left:auto;
		@media (max-width: $mobile) {
			width: 100%;
		}
		.selectLabel {
			font-size: 16px;
			line-height: 1.5em;
			margin: 9px;
			@media (max-width: $mobile) {
				margin-left: 0;;
			}
			&::before {
				content: "\25CF";
				margin: 0 5px 0 0;
				font-size: 16px;
				vertical-align: middle;
			}
			&[data-topic="health"] {
				&::before {
					color: $health-colour;
				}
			}
			&[data-topic="education"] {
				&::before {
					color: $education-colour;
				}
			}
			&[data-topic="councils"] {
				&::before {
					color: $councils-colour;
				}
			}
			&[data-topic="energy"] {
				&::before {
					color: $energy-colour;
				}
			}
			&[data-topic="water"] {
				&::before {
					color: $water-colour;
				}
			}
			&[data-topic="sports"] {
				&::before {
					color: $sports-colour;
				}
			}
			&[data-topic="infrastructure"] {
				&::before {
					color: $infrastructure-colour;
				}
			}
		}
		.selectWrapper {
			flex: 1;
			label {
				font-size: 0;
				display: none;
			}
			input {
				font-family: 'Montserrat', sans-serif;
			}
		}
	}
}

.factoid {
	padding: 25px 0;
	display: flex;
	flex-wrap: no-wrap;
	flex-direction: column;
	text-align: center;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #e3e4e6;

	.regions {
		display: flex;
		flex-flow: row nowrap;
		gap: 10%;
		width: 87%;
		@media (max-width: $mobile) {
			gap: 0;
			// margin-bottom: 10%;
		}
		&[data-comparison="false"] {
			.region {
				width: 100%;
				@media (max-width: $mobile) {
					&:first-child {
						margin-right: 0;
					}
					&:last-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.region {
		flex: none;
		width: 45%;
		@media (max-width: $mobile) {
			&:first-child {
				margin-right: 5%;
			}
			&:last-child {
				margin-left: 5%;
			}
		}
	}
	
	.name {
		color: #333333;
		font-size: 18px;
		font-weight: 500;
		line-height: 23px;
		text-align: center;
		margin: 20px auto;
	}
	.value {
		font-size: 28px;
		line-height: 1em;
		max-width: 100%;

		&[data-topic="health"] {
			color: $health-colour;
		}
		&[data-topic="education"] {
			color: $education-colour;
		}
		&[data-topic="councils"] {
			color: $councils-colour;
		}
		&[data-topic="energy"] {
			color: $energy-colour;
		}
		&[data-topic="water"] {
			color: $water-colour;
		}
		&[data-topic="sports"] {
			color: $sports-colour;
		}
		&[data-topic="infrastructure"] {
			color: $infrastructure-colour;
		}

		&[data-value-type="categorical"] {
			font-size: 16px;
			line-height: 1.5em;
			font-weight: 600;
		}

		&[data-data-available="false"] {
			color: $grey-medium;
			font-weight: 400;
			font-size: 14px;
			height: 28px;
			@media (max-width: $mobile) {
				height: auto;
			}
		}
	}
	.regionName {
		margin: 5px 0 10px;
		font-size: 12px;
		font-weight: 600;
		@media (max-width: $mobile) {
			font-size: 11px;
			line-height: 14px;
		}
		.animatedText {
			display: inline!important;
		}
		&.selected {
			color: $mid-grey;
		}
		&.comparison {
			color: $color-dark-grey;
			&::before {
				content: "\25CF";
				margin: 0 5px 0 0;
				font-size: 14px;
				vertical-align: middle;
				@media (max-width: $mobile) {
					font-size: 11px;
					line-height: 14px;
					vertical-align: top;
				}
			}
			&[data-topic="health"] {
				&::before {
					color: $health-colour;
				}
			}
			&[data-topic="education"] {
				&::before {
					color: $education-colour;
				}
			}
			&[data-topic="councils"] {
				&::before {
					color: $councils-colour;
				}
			}
			&[data-topic="energy"] {
				&::before {
					color: $energy-colour;
				}
			}
			&[data-topic="water"] {
				&::before {
					color: $water-colour;
				}
			}
			&[data-topic="sports"] {
				&::before {
					color: $sports-colour;
				}
			}
			&[data-topic="infrastructure"] {
				&::before {
					color: $infrastructure-colour;
				}
			}
		}
	}
	
	&.general {
		min-width: 306px;
		flex-basis: 337px;
		@media (max-width: $mobile) {
			margin-bottom: 10px;
		}
	}

	&.financial {
		min-width: 191px;
		flex-basis: 194px;
		@media (max-width: $mobile) {
			min-width: 306px;
			flex-basis: 337px;
			margin-bottom: 10px;
		}
		.name {
			margin-top: 0;
		}
		.regions {
			display: flex;
			flex-flow: row wrap;
			gap: 10px;
			@media (max-width: $mobile) {
				gap:0;
			}
			.region {
				width: 100%;
				@media (max-width: $mobile) {
					&:first-child {
						margin-right: 0;
					}
					&:last-child {
						margin-left: 0;
					}
				}
				.regionName.comparison {
					margin-bottom: 0;
				}
			}
		}
	}
}

.animatedText {
	* {
		width: 100%;
	}
}

.footnote {
	margin: 30px 30px 0 0;
	font-size: 12px;
	line-height: 16px;
	font-family: 'Montserrat', sans-serif;
	@media (max-width: $mobile) {
		margin-left: 17px;
		margin-bottom: 0px;
		text-align: left;
	}
}