@import "../../variables.scss";

.launchScreenWrapper {
	h4 {
		margin: 50px 0 30px 0;
	}
	p {
		margin-bottom: 50px;
	}
	img {
		width: 100%;
	}
}