@import './variables.scss';

.loading {
	position: relative;
	display: flex;
	min-height: 600px;
	align-items: center;
	justify-content: space-around;
  }
  
  @keyframes spinner {
	to {
	  transform: rotate(360deg);
	}
  }
  
  .spinner:before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-top: 20px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid $color-light-grey;
	border-top-color: $color-dark-grey;
	animation: spinner 0.6s linear infinite;
  }
  
.App {
	box-sizing: border-box;
	max-width: 1110px;
	* {
	  box-sizing: border-box;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  input[type='text'] {
		height: auto;
		line-height: initial;
	  }
	}
	ul li::before,
	ul li::before,
	ul li::before {
	  content: '';
	  display: none;
	}
  
	margin: 50px auto 100px auto;
	font-family: 'Montserrat', sans-serif;
  
	font-size: 16px;
	color: $color-black;
	line-height: 22px;
	@media (max-width: $mobile) {
	  margin-top: 24px;
	}
}